import React, {useContext} from 'react';
import {navigate} from 'gatsby';
import {NssspContext} from '../gatsby-theme-carbon/components/Layout';

import {
  ProgressIndicator,
  ProgressStep,
} from "carbon-components-react";

function TasksNav({current}) {
    const nsssp = useContext(NssspContext);
    return (
      <>
        <ProgressIndicator vertical currentIndex={-1} onChange={(e) => {
            navigate(nsssp?.tasks[e].path)
          }}>
          {nsssp?.tasks.map((task) => {
            return (
              <ProgressStep
                current={task.id === current}
                complete={nsssp?.answers && nsssp?.answers.completed_tasks && nsssp?.answers.completed_tasks.includes(task.id)}
                key={task.id}
                label={task.title}
                value={task.id}
              />
            );
          })}
        </ProgressIndicator>
      </>
    )
}

export default TasksNav
